import { TargetingType } from './types';
// rules
import { checkAtLeastDomNodesRule, validateAtLeastDomNodesRule } from './targetingRules/atLeastDomNodes';
import { checkBrowserRule, validateBrowserRule } from './targetingRules/browserRule';
import { checkCookieRule, validateCookieRule } from './targetingRules/cookie';
import { checkCurrentContextRule, validateCurrentContextRule } from './targetingRules/currentContext';
import { checkDateRangeRule, validateDateRangeRule } from './targetingRules/dateRange';
import { checkDomNodeRule, validateDomNodeRule } from './targetingRules/domNode';
import { isTestWikiRule, validateIsTestWikiRule } from './targetingRules/isTestWiki';
import { checkMwConfigRule, validateMwConfigRule } from './targetingRules/mwConfig';
import { checkReferrerRule, validateReferrerRule } from './targetingRules/referrer';
import { checkScreenSizeRule, validateScreenSizeRule } from './targetingRules/screenSize';
import { checkSilverSurferActionRule, validateSilverSurferActionsRule } from './targetingRules/silverSurferAction';
import { checkTestOnlyInDevRule, validateTestOnlyInDevRule } from './targetingRules/testOnlyInDev';
import { checkTestOnlyOnBranchRule, validateTestOnlyOnBranchRule } from './targetingRules/testOnlyOnBranch';
import { checkUrlRule, validateUrlRule } from './targetingRules/url';
import { checkUserDeviceRule, validateUserDeviceRule } from './targetingRules/userDevice';
import { checkUserGeoRule, validateUserGeoRule } from './targetingRules/userGeo';
import { checkUserLoggedInRule, validateUserLoggedInRule } from './targetingRules/userLoggedIn';
import { checkVisitedPagesRule, validateVisitedPagesRule } from './targetingRules/visitedPages';
import { checkWikiCategory, validateWikiCategoryRule } from './targetingRules/wikiCategory';
import { checkAlwaysLoadRule, validateAlwaysLoadRule } from './targetingRules/_alwaysLoad';
import { checkFandomContextRule, validateFandomContextRule, } from 'pathfinder/rules-engine/targetingRules/fandomContext';
import { checkFandomQuizzesRule, validateFandomQuizzesRule, } from 'pathfinder/rules-engine/targetingRules/fandomQuizzes';
import { checkNoAdsRule, validateNoAdsRule } from './targetingRules/noAds';
/** List of all supported rules */
export function getAllTargetingRules() {
    return {
        // test only rules
        [TargetingType.TestOnlyInDev]: checkTestOnlyInDevRule,
        [TargetingType.TestOnlyOnBranch]: checkTestOnlyOnBranchRule,
        // system rules
        [TargetingType.AlwaysLoad]: checkAlwaysLoadRule,
        // SilverSurfer rules
        [TargetingType.CurrentContext]: checkCurrentContextRule,
        [TargetingType.UserLoggedIn]: checkUserLoggedInRule,
        [TargetingType.VisitedPages]: checkVisitedPagesRule,
        [TargetingType.SilverSurferAction]: checkSilverSurferActionRule,
        [TargetingType.Referrer]: checkReferrerRule,
        // Pathfinder internal rules
        [TargetingType.UserDevice]: checkUserDeviceRule,
        [TargetingType.DomNode]: checkDomNodeRule,
        [TargetingType.DateRange]: checkDateRangeRule,
        [TargetingType.UserGeo]: checkUserGeoRule,
        [TargetingType.Cookie]: checkCookieRule,
        [TargetingType.ScreenSize]: checkScreenSizeRule,
        [TargetingType.Url]: checkUrlRule,
        [TargetingType.WikiCategory]: checkWikiCategory,
        [TargetingType.MwConfig]: checkMwConfigRule,
        [TargetingType.IsTestWiki]: isTestWikiRule,
        [TargetingType.AtLeastDomNodes]: checkAtLeastDomNodesRule,
        [TargetingType.Browser]: checkBrowserRule,
        [TargetingType.FandomContext]: checkFandomContextRule,
        [TargetingType.FandomQuizzes]: checkFandomQuizzesRule,
        [TargetingType.NoAds]: checkNoAdsRule,
    };
}
/** Validators for all supported rules */
export function getAllValidateRules() {
    return {
        // test only rules
        [TargetingType.TestOnlyInDev]: validateTestOnlyInDevRule,
        [TargetingType.TestOnlyOnBranch]: validateTestOnlyOnBranchRule,
        // system rules
        [TargetingType.AlwaysLoad]: validateAlwaysLoadRule,
        // SilverSurfer rules
        [TargetingType.CurrentContext]: validateCurrentContextRule,
        [TargetingType.UserLoggedIn]: validateUserLoggedInRule,
        [TargetingType.VisitedPages]: validateVisitedPagesRule,
        [TargetingType.SilverSurferAction]: validateSilverSurferActionsRule,
        [TargetingType.Referrer]: validateReferrerRule,
        // Pathfinder internal rules
        [TargetingType.UserDevice]: validateUserDeviceRule,
        [TargetingType.DomNode]: validateDomNodeRule,
        [TargetingType.DateRange]: validateDateRangeRule,
        [TargetingType.UserGeo]: validateUserGeoRule,
        [TargetingType.Cookie]: validateCookieRule,
        [TargetingType.ScreenSize]: validateScreenSizeRule,
        [TargetingType.Url]: validateUrlRule,
        [TargetingType.WikiCategory]: validateWikiCategoryRule,
        [TargetingType.MwConfig]: validateMwConfigRule,
        [TargetingType.IsTestWiki]: validateIsTestWikiRule,
        [TargetingType.AtLeastDomNodes]: validateAtLeastDomNodesRule,
        [TargetingType.Browser]: validateBrowserRule,
        [TargetingType.FandomContext]: validateFandomContextRule,
        [TargetingType.FandomQuizzes]: validateFandomQuizzesRule,
        [TargetingType.NoAds]: validateNoAdsRule,
    };
}
