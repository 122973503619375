export function getMockedSponsoredLogos() {
    return [
        {
            id: -1,
            widgetType: 'sponsored-logo-with-flyout',
            active: true,
            startDateTime: null,
            endDateTime: null,
            ucpTargetings: [
                {
                    id: -1,
                    wikiId: null,
                    articleIds: [],
                    skins: [],
                    geos: [],
                    genres: [],
                    categories: [],
                },
            ],
            states: [
                {
                    id: -1,
                    startDateTime: null,
                    logoClickThroughUrl: 'https://fandom.com',
                    haloClickThroughUrl: 'https://fandom.com',
                    haloPixel: 'https://static.wikia.nocookie.net/6a181c72-e8bf-419b-b4db-18fd56a0eb60',
                    sponsorText: 'sponsor',
                    labelText: 'Sponsored Ad',
                    images: [
                        {
                            id: -1,
                            size: {
                                width: 36,
                                height: 36,
                            },
                            url: 'https://static.wikia.nocookie.net/fandom-ae-assets/programmatic/widgets/sponsored-logo-with-flyout-preview/QE/QE-SPONSORED-LOGO-36x36.webp',
                        },
                        {
                            id: -1,
                            size: {
                                width: 322,
                                height: 154,
                            },
                            url: 'https://static.wikia.nocookie.net/fandom-ae-assets/programmatic/widgets/sponsored-logo-with-flyout-preview/QE/QE-HALO-EFFECT-322x154.webp',
                        },
                    ],
                },
            ],
        },
    ];
}
