import { __awaiter } from "tslib";
import { getSponsoredWidgetsByDeviceMetadata, getSponsoredWidgetsByWidgetId, } from 'pathfinder/api/sponsoredWidgets/sponsoredWidget';
import { getMockedSponsoredLogos, } from 'pathfinder/api/sponsoredWidgets/sponsoredLogoWithFlyout';
import { chooseSponsoredWidget } from 'pathfinder/utils/sponsoredWidgets/chooseSponsoredWidget';
import { chooseSponsoredWidgetState } from 'pathfinder/utils/sponsoredWidgets/chooseSponsoredWidgetState';
import { chooseImage } from 'pathfinder/utils/sponsoredWidgets/chooseImage';
export function getSponsoredLogosWithFlyout(isForced) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const widgetType = 'sponsored-logo-with-flyout';
        if (isForced) {
            return getMockedSponsoredLogos();
        }
        const sponsoredLogosWithFlyout = (_a = (yield getSponsoredWidgetsByWidgetId(widgetType))) !== null && _a !== void 0 ? _a : (yield getSponsoredWidgetsByDeviceMetadata(widgetType));
        if (!sponsoredLogosWithFlyout || !sponsoredLogosWithFlyout.length) {
            return null;
        }
        return sponsoredLogosWithFlyout;
    });
}
export function getSponsoredLogoWithFlyoutBaseData(sponsoredLogosWithFlyout, isForced, device) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return __awaiter(this, void 0, void 0, function* () {
        const widgetType = 'sponsored-logo-with-flyout';
        const logoSize = { width: 36, height: 36 };
        const haloSize = { width: 322, height: 154 };
        const chosenSponsoredLogoWithFlyout = isForced
            ? sponsoredLogosWithFlyout[0]
            : chooseSponsoredWidget(sponsoredLogosWithFlyout !== null && sponsoredLogosWithFlyout !== void 0 ? sponsoredLogosWithFlyout : [], device, widgetType);
        if (!chosenSponsoredLogoWithFlyout) {
            return null;
        }
        const chosenSponsoredLogoWithFlyoutState = chooseSponsoredWidgetState((_a = chosenSponsoredLogoWithFlyout.states) !== null && _a !== void 0 ? _a : [], device, widgetType, chosenSponsoredLogoWithFlyout.id);
        if (!chosenSponsoredLogoWithFlyoutState) {
            return null;
        }
        const logoImage = chooseImage((_b = chosenSponsoredLogoWithFlyoutState.images) !== null && _b !== void 0 ? _b : [], logoSize, widgetType, chosenSponsoredLogoWithFlyout.id, chosenSponsoredLogoWithFlyoutState.id);
        const haloImage = chooseImage((_c = chosenSponsoredLogoWithFlyoutState.images) !== null && _c !== void 0 ? _c : [], haloSize, widgetType, chosenSponsoredLogoWithFlyout.id, chosenSponsoredLogoWithFlyoutState.id);
        if (!logoImage || !haloImage) {
            return null;
        }
        return {
            sponsoredLogoWithFlyoutId: chosenSponsoredLogoWithFlyout.id,
            logoClickThroughUrl: (_d = chosenSponsoredLogoWithFlyoutState.logoClickThroughUrl) !== null && _d !== void 0 ? _d : '',
            haloClickThroughUrl: (_e = chosenSponsoredLogoWithFlyoutState.haloClickThroughUrl) !== null && _e !== void 0 ? _e : '',
            haloPixel: (_f = chosenSponsoredLogoWithFlyoutState.haloPixel) !== null && _f !== void 0 ? _f : '',
            sponsorText: (_g = chosenSponsoredLogoWithFlyoutState.sponsorText) !== null && _g !== void 0 ? _g : '',
            labelText: (_h = chosenSponsoredLogoWithFlyoutState.labelText) !== null && _h !== void 0 ? _h : '',
            images: {
                logo: logoImage,
                halo: haloImage,
            },
        };
    });
}
