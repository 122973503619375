import { InsertElementMethod, InsertType, ModuleType, TargetingType, } from 'pathfinder/rules-engine/types';
import _operators from 'pathfinder/rules-engine/targetingRules/_operators';
import { ARTICLES_RULE, DESKTOP_RULE, HOMEPAGE_RULE, MEDIAWIKI_RULE, MOBILE_RULE, NO_ADS_REASONS_PRESENT_RULE, USER_ANON_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
export var SponsoredWidgetCountdownClockConsumptionModuleVariant;
(function (SponsoredWidgetCountdownClockConsumptionModuleVariant) {
    SponsoredWidgetCountdownClockConsumptionModuleVariant[SponsoredWidgetCountdownClockConsumptionModuleVariant["DESKTOP"] = 0] = "DESKTOP";
    SponsoredWidgetCountdownClockConsumptionModuleVariant[SponsoredWidgetCountdownClockConsumptionModuleVariant["MOBILE"] = 1] = "MOBILE";
})(SponsoredWidgetCountdownClockConsumptionModuleVariant || (SponsoredWidgetCountdownClockConsumptionModuleVariant = {}));
const SELECTOR_MOBILE = '.portable-infobox-wrapper, .mw-parser-output > p, .fandom-stats';
const INSERT_CONFIG_DESKTOP = {
    type: InsertType.Element,
    selectors: [
        {
            selector: '#WikiaRail, .main-page-tag-rcs #top_boxad',
            method: InsertElementMethod.After,
        },
        {
            selector: '.main-page-tag-rcs',
            method: InsertElementMethod.Prepend,
        },
    ],
};
const INSERT_CONFIG_MOBILE = {
    type: InsertType.Element,
    selectors: [
        {
            selector: SELECTOR_MOBILE,
            method: InsertElementMethod.After,
        },
    ],
};
const ENOUGH_CONTENT_MOBILE_RULE = {
    type: TargetingType.AtLeastDomNodes,
    selector: SELECTOR_MOBILE,
    min: 2,
};
const sponsoredWidgetsCountdownClockConsumptionModules = [
    {
        name: 'sponsored-widgets-countdown-clock-consumption-module-desktop',
        type: ModuleType.SponsoredWidgetsCountdownClockConsumptionModule,
        insertConfig: INSERT_CONFIG_DESKTOP,
        config: {
            countdownClocks: [],
            variant: SponsoredWidgetCountdownClockConsumptionModuleVariant.DESKTOP,
        },
        targeting: _operators.all(DESKTOP_RULE, MEDIAWIKI_RULE, USER_ANON_RULE, _operators.any(ARTICLES_RULE, HOMEPAGE_RULE), NO_ADS_REASONS_PRESENT_RULE),
    },
    {
        name: 'sponsored-widgets-countdown-clock-consumption-module-mobile',
        type: ModuleType.SponsoredWidgetsCountdownClockConsumptionModule,
        insertConfig: INSERT_CONFIG_MOBILE,
        config: {
            countdownClocks: [],
            variant: SponsoredWidgetCountdownClockConsumptionModuleVariant.MOBILE,
        },
        targeting: _operators.all(MOBILE_RULE, MEDIAWIKI_RULE, USER_ANON_RULE, ENOUGH_CONTENT_MOBILE_RULE, _operators.any(ARTICLES_RULE, HOMEPAGE_RULE), NO_ADS_REASONS_PRESENT_RULE),
    },
];
export default sponsoredWidgetsCountdownClockConsumptionModules;
