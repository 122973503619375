import { __awaiter } from "tslib";
import deepmerge from 'deepmerge';
import { isGlobalDebugMode } from 'utils/env';
import log from '../utils/log';
import { ModuleType } from './types';
// modules
import haloEffectVerticalModules from './modules/haloEffectVerticalModule';
import inWikiEmailModules from './modules/inwikiemail';
import marketingNotificationsModules from './modules/marketingNotifications';
import notificationCMSModules from './modules/notificationCMS';
import quizConsumptionModules from './modules/quizConsumptionModule';
import quizCreation from './modules/quizCreation';
import quizMetaEditorModules from './modules/quizMetaEditorModule';
import sponsoredWidgetsCountdownClockConsumptionModules from './modules/sponsoredWidgetsCountdownClockConsumptionModule';
import sponsoredWidgetsSponsoredLogoConsumptionModules from './modules/sponsoredWidgetsSponsoredLogoConsumptionModule';
import sponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModules from './modules/sponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModule';
import debugModules from './modules/_debug';
import webPushModules from './modules/webPush';
import collapseToCModules from 'pathfinder/rules-engine/modules/collapseToCModule';
// module function
import { getHaloEffectVerticalModuleMethods } from 'pathfinder/modules/HaloEffectVerticalModule/loader';
import { getMarketingNotificationsModule } from 'pathfinder/modules/MarketingNotifications/loader';
import { getNotificationCMSModule } from 'pathfinder/modules/NotificationCMS/loader';
import { getDiscussionQuizConsumptionModule } from 'pathfinder/modules/QuizConsumptionModule/loaderDisc';
import { getFanhubQuizConsumptionModule } from 'pathfinder/modules/QuizConsumptionModule/loaderFanhub';
import { getTriviaQuizConsumptionModule } from 'pathfinder/modules/QuizConsumptionModule/loaderTrivia';
import { getWikiQuizConsumptionModule } from 'pathfinder/modules/QuizConsumptionModule/loaderWiki';
import { getQuizCreationModuleMethods } from 'pathfinder/modules/QuizCreation';
import { getQuizMetaEditorModule } from 'pathfinder/modules/QuizMetaEditorModule/loader';
import { getSponsoredWidgetsCountdownClockConsumptionModule } from 'pathfinder/modules/SponsoredWidgetsCountdownClockConsumptionModule/loader';
import { getSponsoredWidgetsSponsoredLogoConsumptionModule } from 'pathfinder/modules/SponsoredWidgetsSponsoredLogoConsumptionModule/loader';
import { getSponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModule } from 'pathfinder/modules/SponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModule/loader';
import { getInWikiEmailModuleMethods } from '../modules/InWikiEmailModule/loader';
import { getDebugMenuLoader } from '../modules/_DebugMenuModule/loader';
import { getWebPushModule } from 'pathfinder/modules/WebPushModule/loader';
import { getCollapseToCModule } from 'pathfinder/modules/CollapseToCModule/loader';
/** Pure, unedited list of modules */
export function getAllRawModules() {
    return []
        .concat(debugModules, inWikiEmailModules, haloEffectVerticalModules, quizConsumptionModules, quizCreation, quizMetaEditorModules, notificationCMSModules, marketingNotificationsModules, sponsoredWidgetsCountdownClockConsumptionModules, sponsoredWidgetsSponsoredLogoConsumptionModules, sponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModules, webPushModules, collapseToCModules)
        .filter(Boolean);
}
/** Returns all registered modules; ideally coming from the backend */
export function getAllModules() {
    /** Returns all registered modules; ideally coming from the backend */
    let allModules = getAllRawModules();
    // add extra field
    allModules = allModules.map((module) => deepmerge({ internalState: {} }, module));
    // export all module configurations while global debug is enabled
    if (isGlobalDebugMode()) {
        log.debug('RulesEngine: All the modules exposed to window.pathfinderAllModules');
        // @ts-expect-error ignore `not on type` warning
        window.pathfinderAllModules = allModules;
    }
    return allModules;
}
/** Returns module's getModuleMethod */
export function getModuleMethodFunction(type) {
    const ALL_MODULE_METHODS = {
        // System modules
        [ModuleType.DebugMenu]: getDebugMenuLoader,
        // Permanent modules
        [ModuleType.InWikiEmail]: getInWikiEmailModuleMethods,
        [ModuleType.MarketingNotifications]: getMarketingNotificationsModule,
        [ModuleType.NotificationCMS]: getNotificationCMSModule,
        [ModuleType.HaloEffectVertical]: getHaloEffectVerticalModuleMethods,
        [ModuleType.SponsoredWidgetsCountdownClockConsumptionModule]: getSponsoredWidgetsCountdownClockConsumptionModule,
        [ModuleType.SponsoredWidgetsSponsoredLogoConsumptionModule]: getSponsoredWidgetsSponsoredLogoConsumptionModule,
        [ModuleType.SponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModule]: getSponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModule,
        // Quizzes module.
        [ModuleType.QuizCreation]: getQuizCreationModuleMethods,
        [ModuleType.WikiQuizConsumptionModule]: getWikiQuizConsumptionModule,
        [ModuleType.QuizMetaEditorModule]: getQuizMetaEditorModule,
        [ModuleType.DiscussionQuizConsumptionModule]: getDiscussionQuizConsumptionModule,
        [ModuleType.TriviaQuizConsumptionModule]: getTriviaQuizConsumptionModule,
        [ModuleType.FanhubQuizConsumptionModule]: getFanhubQuizConsumptionModule,
        // A/B Testing Modules
        [ModuleType.WebPush]: getWebPushModule,
        [ModuleType.CollapseToC]: getCollapseToCModule,
    };
    const emptyGetModuleMethodFunction = () => __awaiter(this, void 0, void 0, function* () { return false; });
    return typeof ALL_MODULE_METHODS[type] === 'function' ? ALL_MODULE_METHODS[type] : emptyGetModuleMethodFunction;
}
