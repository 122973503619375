import { InsertElementMethod, InsertType, ModuleType, } from 'pathfinder/rules-engine/types';
import _operators from 'pathfinder/rules-engine/targetingRules/_operators';
import { DESKTOP_RULE, F2_RULE, MEDIAWIKI_RULE, NAMESPACE_0_RULE, NO_ADS_REASONS_PRESENT_RULE, USER_ANON_RULE, } from 'pathfinder/rules-engine/targetingRules/_templates';
const selector = '.global-explore-navigation__explore';
const INSERT_CONFIG = {
    type: InsertType.Element,
    selectors: [
        {
            selector,
            method: InsertElementMethod.Before,
        },
    ],
};
const sponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModules = [
    {
        name: 'sponsored-widgets-sponsored-logo-with-flyout-consumption-module',
        type: ModuleType.SponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModule,
        insertConfig: INSERT_CONFIG,
        config: {
            sponsoredLogosWithFlyout: [],
        },
        targeting: _operators.any(_operators.all(USER_ANON_RULE, DESKTOP_RULE, MEDIAWIKI_RULE, NAMESPACE_0_RULE, NO_ADS_REASONS_PRESENT_RULE), _operators.all(USER_ANON_RULE, DESKTOP_RULE, F2_RULE, NO_ADS_REASONS_PRESENT_RULE)),
    },
];
export default sponsoredWidgetsSponsoredLogoWithFlyoutConsumptionModules;
